<script setup lang="ts">
import Swal from "sweetalert2";

const { initAccount } = useAccount();
const { loading, initAnotherAccount } = useAnotherAccount();
const generalStore = useGeneralStore();
const email = ref<string>("");

const toggleConnection = () =>
  generalStore.isUsingAnotherAccount ? disconnect() : connect();

const connect = async () => {
  // Email validation
  if (!validateEmail(email.value)) {
    return Swal.fire({
      title: "Email invalide",
      icon: "error",
      toast: true,
      position: "top-end",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  }

  try {
    await initAnotherAccount(email.value);
  } catch (error) {
    console.error(error);
  }
  generalStore.$patch({ isUsingAnotherAccount: email.value });
};

const disconnect = async () => {
  try {
    generalStore.$patch({ account_id: "" });
    await initAccount();
  } catch (error) {
    console.error(error);
  }
  email.value = "";
  generalStore.$patch({ isUsingAnotherAccount: null });
};

const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

onMounted(() => {
  if (generalStore.isUsingAnotherAccount) {
    email.value = generalStore.isUsingAnotherAccount;
  }
});
</script>

<template>
  <ClientOnly>
    <div class="flex items-center" v-if="generalStore.isAdmin">
      <input
        v-model="email"
        @keyup.enter="toggleConnection"
        class="input mr-2 w-72"
        placeholder="Email utilisateur"
        id="email"
        type="email"
        name="email"
        :disabled="
          (generalStore.isUsingAnotherAccount &&
            generalStore.isUsingAnotherAccount !== '') ||
          loading
        "
      />
      <button
        @click="toggleConnection"
        class="flex items-center gap-2 w-36 h-10 justify-center text-sm"
        :class="{
          'btn-primary': !generalStore.isUsingAnotherAccount,
          'btn-secondary': generalStore.isUsingAnotherAccount,
        }"
        :disabled="loading"
      >
        <font-awesome-icon
          :icon="['fas', 'fa-spinner', 'fa-spin']"
          :size="'sm'"
          v-if="loading"
        ></font-awesome-icon>
        <span v-else>{{
          generalStore.isUsingAnotherAccount ? "Se déconnecter" : "Se connecter"
        }}</span>
      </button>
    </div>
  </ClientOnly>
</template>
